import React, {useState, useEffect} from 'react';
import {useAuth} from '../auth/AuthProvider';
import {useNavigate} from 'react-router-dom';
import {checkCreatorExists, checkUsernameExists, createCreator} from '../api';
import './SignUp.css';
import googleIcon from '../assets/google-icon.png';
import Header from '../components/Header';
import Footer from '../components/Footer';
import {getRedirectResult, GoogleAuthProvider, signInWithRedirect, signOut} from 'firebase/auth';
import {auth} from '../firebase';

const SignUp: React.FC = () => {
    const {signUpWithGoogle, currentUser, logOut, getCurrentUser} = useAuth();
    const navigate = useNavigate();
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const [redirectLoading, setRedirectLoading] = useState(true); // New state for handling redirect loading

    useEffect(() => {
        console.log('useEffect');
        // Handle redirect result if redirected back from Google sign-in
        const handleRedirectResult = async () => {
            console.log('handleRedirectResult');
            setRedirectLoading(true); // Start showing the loading spinner

            try {
                const result = await getRedirectResult(auth);
                console.log(result);

                if (result && result.user) {
                    const updatedUser = result.user;
                    console.log('updatedUser', updatedUser);

                    if (updatedUser && updatedUser.email) {
                        const creatorExists = await checkCreatorExists(updatedUser.email);
                        console.log(creatorExists);
                        if (creatorExists === 'error') {
                            setError('An error occurred while checking the creator. Please try again later.');
                            return;
                        }

                        if (creatorExists === 'creator-not-found') {
                            const created = await createCreator(updatedUser.email, updatedUser.displayName || '', updatedUser.uid);
                            console.log('created', created);

                            if (created === 'conflict') {
                                setError('An account with this email already exists. Please log in.');
                                return;
                            } else if (created === 'error') {
                                setError('Failed to create an account. Please try again later.');
                                return;
                            }
                        }

                        console.log('Redirect user: ', result.user);
                        // Now check the user's status to decide where to navigate
                        await checkUserStatus();
                    }

                    // User signed in via redirect, set in context
                }
            } catch (error) {
                console.error('Error with redirect result:', error);
                setError('Something went wrong with Google sign-in.');
            } finally {
                setRedirectLoading(false); // Stop showing the loading spinner
            }
        };

        handleRedirectResult();
    }, []);

    const checkUserStatus = async (user?: any) => {
        setLoading(true);
        setError(null);

        console.log('checkUserStatus', user, currentUser);

        try {
            // currentUser = getCurrentUser();
            const activeUser = user || currentUser;
            console.log(user, currentUser);
            console.log(activeUser);

            if (activeUser && activeUser.email) {
                const result = await checkUsernameExists(activeUser.email);

                if (result === 'error') {
                    await logOut();
                    setError('There was an issue with your session. Please log in again.');
                } else if (result === 'creator-not-found') {
                    setError('No account found for this email. Please sign up first.');
                    await logOut();
                } else if (result) {
                    navigate(`/${result}`);
                } else {
                    navigate('/creator/setup-username');
                }
            }
        } catch (error) {
            console.error('Error checking user status:', error);
            setError('There was an issue with your session. Please log in again.');
            await logOut();
        } finally {
            setLoading(false);
        }
    };

    const handleSignUp = async () => {
        setError(null);
        setLoading(true);

        try {
            await logOut();
            await signOut(auth);
            const isDevelopment = false; //window.innerWidth <= 768; // Check if the user is on mobile
            console.log('isDevelopment', isDevelopment);


            const provider = new GoogleAuthProvider();
            provider.setCustomParameters({
                prompt: 'select_account', // Force account selection
            });
            // provider.setCustomParameters({
            //     'login_hint': 'user@example.com'
            // });

            console.log(provider);

            if (!isDevelopment) {
                await signInWithRedirect(auth, provider);
            }

            // If popup is used (for desktop), handle the result immediately
            if (isDevelopment) {
                await signUpWithGoogle(!isDevelopment);

                const updatedUser = getCurrentUser(); // Get the updated user information
                console.log(updatedUser);
                if (updatedUser && updatedUser.email) {
                    const creatorExists = await checkCreatorExists(updatedUser.email);

                    if (creatorExists === 'error') {
                        setError('An error occurred while checking the creator. Please try again later.');
                        return;
                    }

                    if (creatorExists === 'creator-not-found') {
                        const created = await createCreator(updatedUser.email, updatedUser.displayName || '', updatedUser.uid);

                        if (created === 'conflict') {
                            setError('An account with this email already exists. Please log in.');
                            return;
                        } else if (created === 'error') {
                            setError('Failed to create an account. Please try again later.');
                            return;
                        }
                    }

                    // Now check the user's status to decide where to navigate
                    await checkUserStatus();
                }
            }
        } catch (error) {
            console.error('Sign-up failed:', error);
            setError('Something went wrong. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Header/>
            <div className="signup-container">
                <div className="signup-box">

                    {redirectLoading ? (
                        <div className="loading-overlay">
                            <div className="loading-spinner"></div>
                            <p>Signing in...</p>
                        </div>
                    ) : (
                        <>
                            <h2 className="signup-heading">Welcome to Solt</h2>
                            <p className="signup-subtext">Sign up as a content creator</p>
                            {error && <p className="error-message">{error}</p>}
                            <button className="google-signup-button" onClick={handleSignUp} disabled={loading}>
                                {loading ? (
                                    <span className="loading-spinner"></span>
                                ) : (
                                    <>
                                        <img src={googleIcon} alt="Google Icon" className="google-icon"/>
                                        Continue with Google
                                    </>
                                )}
                            </button>
                            <p className="signup-disclaimer">
                                By continuing you agree to our <a href="/privacy">Privacy Policy</a>, <a
                                href="/terms">Terms of Service</a> and <a href="/cookies">Cookies Policy</a>.
                            </p>
                        </>
                    )}
                </div>
            </div>
            <Footer/>
        </>
    );
};

export default SignUp;
