import React, {useCallback, useEffect, useRef, useState} from 'react';
import {ToastContainer, toast, Id} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {getAllPostsWithCreatorDetails, getUsernameByFirebaseUID, searchPosts, updateReactionCount} from '../api';
import './Home.css';
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry";
import ClipLoader from 'react-spinners/ClipLoader';
import soltLogo from '../assets/logo.png';
import {Helmet, HelmetProvider} from 'react-helmet-async';
import ReactGA from "react-ga4";
import posthog from 'posthog-js';
import {formatDistanceToNow} from 'date-fns';
import BottomModal from "../components/BottomModal";
import { throttle } from 'lodash'; // Import throttle to limit scroll events

import {LazyLoadImage} from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css'; // Optional for blur effect

import redirectIcon from '../assets/redirect-icon.png';
import closeIcon from '../assets/close-icon.png'; // The icon for closing the modal
import infoIcon from '../assets/info-icon.png';
import searchIcon from '../assets/search-icon.png'; // Add search icon path
import enterIcon from '../assets/enter-icon.png'; // Add enter icon path
import exitIcon from '../assets/exit-icon.png';
import sendIcon from '../assets/send-icon.png'; // Add send icon path
import starIcon from '../assets/star-icon.png';

import likedReactionIcon from "../assets/liked-reaction-icon.png";
import normalReactionIcon from "../assets/normal-reaction-icon.png";
import restaurantPlaceIcon from "../assets/restaurant-place-icon.png";
import {extractLocationInfo} from "../helpers/helpers";
import FloatingModal from "../components/FloatingModal";
import {Link} from "react-router-dom";
import {useAuth} from "../auth/AuthProvider";

ReactGA.initialize("G-TLM63YPZGE");

declare const plausible: (eventName: string, options?: { props: Record<string, string> }) => void; // Plausible typing

interface HomePost {
    post: {
        _id: string | number;
        images: string[];
        title: string;
        description: string;
        location?: string;
        updated?: string;
        google_place_id?: string;
        location_english_formatted?: string;
        created?: string;
        reactionCount?: number;
        ratings?: {
            dishRating?: number; // New field for taste rating
            wouldEatAgain?: boolean; // New field for "Would you eat it again?"
        };
    },
    creator: {
        name: string;
        username: string;
        profileImage?: string;
    };
}

posthog.init('phc_Pz5SLU7O6UiCM3I5oabytoGoVTAkiMdROvO34hzDmiI', {
    api_host: 'https://eu.i.posthog.com',
    person_profiles: 'always',
});

const Home: React.FC = () => {
    const {currentUser} = useAuth();
    const isAuthenticated = !!currentUser;
    const [username, setUsername] = useState<string | null>(null); // State for the username
    const [posts, setPosts] = useState<HomePost[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [selectedPost, setSelectedPost] = useState<HomePost | null>(null);
    const [isViewing, setIsViewing] = useState<boolean>(false);
    const [isOverlayOpen, setIsOverlayOpen] = useState(false);
    const [retryCount, setRetryCount] = useState(0); // Retry mechanism
    const toastIdRef = useRef<Id | undefined>(undefined); // Use `undefined` instead of `null`
    const [animationClasses, setAnimationClasses] = useState<{ [key: string]: string }>({}); // Key-value store for postId and animation class
    const [isOriginalImageLoaded, setIsOriginalImageLoaded] = useState(false);

    const pageDescription = `Explore the best dishes at top restaurants, handpicked by food content creators. Discover unique flavors, see where to eat them, and share your own foodie favorites.`;

    const [isModalOpen, setIsModalOpen] = useState(false);

    const [isSearchOpen, setIsSearchOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    // const [userLocation, setUserLocation] = useState<GeolocationPosition | null>(null);
    const debounceTimeoutRef = useRef<NodeJS.Timeout | null>(null); // To store debounce timer reference
    const searchInputRef = useRef<HTMLInputElement>(null); // Add a ref for the search input

    const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(1);
    const limit = 10;
    const [isFetching, setIsFetching] = useState<boolean>(false); // State to prevent multiple fetches
    const [shouldFetch, setShouldFetch] = useState(false);

    // State to track the loaded status for each post
    const [thumbnailLoadState, setThumbnailLoadState] = useState<{ [key: string]: boolean }>({});

    const handleThumbnailLoad = (postId: string | number) => {
        setThumbnailLoadState((prevState) => ({
            ...prevState,
            [postId]: true, // Mark this post's thumbnail as loaded
        }));
    };

    // Function to fetch more posts when the user scrolls to the bottom
    const handleScroll = useCallback(
        throttle(() => {
            console.log('search Query: ', searchQuery, searchQuery.trim());
            // Check if we're already loading or if there's nothing more to load
            if ((searchQuery && searchQuery.trim()) || isLoadingMore || currentPage >= totalPages || isFetching) return;

            console.log("after if");
            const scrollPosition = window.innerHeight + document.documentElement.scrollTop;
            const threshold = document.documentElement.offsetHeight - 100; // Trigger 100px before bottom

            if (scrollPosition >= threshold) {
                console.log("Fetching more posts...");
                setIsFetching(true); // Mark as fetching
                handleShowMore(); // Trigger your existing function to fetch posts
            }
        }, 300), // Throttle to limit calls
        [currentPage, totalPages, isFetching, isLoadingMore, searchQuery]
    );

    useEffect(() => {
        window.addEventListener('scroll', handleScroll); // Attach scroll listener
        return () => window.removeEventListener('scroll', handleScroll); // Cleanup listener
    }, [handleScroll]); // Reapply if dependencies change

    const handleShowMore = async () => {
        if (isLoadingMore || currentPage >= totalPages || isFetching) return;

        setIsFetching(true); // Lock to prevent overlapping calls

        const nextPage = currentPage + 1; // Calculate the next page based on the current state
        console.log(`Loading more posts for page: ${nextPage}`);
        await fetchPosts(nextPage); // Pass the calculated page number to the fetch function
        setCurrentPage(nextPage); // Update the current page after fetching is complete

        setIsFetching(false); // Unlock after fetching
    };


    // Fetch the username by Firebase UID on load if the user is authenticated
    useEffect(() => {
        const fetchUsername = async () => {
            if (currentUser && isAuthenticated) {
                const result = await getUsernameByFirebaseUID(currentUser.uid); // assuming `uid` is the Firebase UID

                if (result && result !== 'error' && result !== 'not-found') {
                    setUsername(result);
                } else {
                    console.error('Error fetching username or username not found');
                }
            }
        };

        fetchUsername(); // Call the async function to fetch the username
    }, [currentUser]);

    // Function to request user location
    const requestUserLocation = () => {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    console.log(position);
                    // setUserLocation(position);
                    setSearchQuery(position.coords.latitude + ' ' + position.coords.longitude);
                    console.log("User location:", position.coords.latitude, position.coords.longitude);
                },
                (error) => {
                    console.error("Error retrieving location:", error);
                },
                {timeout: 10000, maximumAge: 0}
            );
        } else {
            console.error("Geolocation is not supported by this browser.");
        }
    };

    // Handle search input change with debounced search function
    const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        console.log('handleSearchInputChange');
        const query = e.target.value;
        setSearchQuery(query);

        if (debounceTimeoutRef.current) {
            clearTimeout(debounceTimeoutRef.current);
        }

        debounceTimeoutRef.current = setTimeout(async () => {
            if (query.trim()) {
                await performSearch(query);
                if (searchInputRef.current) {
                    searchInputRef.current.blur(); // Hide the keyboard after search
                }
            } else {
                setPosts([]); // Clear search results if query is empty
                fetchPosts(currentPage);
                if (searchInputRef.current) {
                    searchInputRef.current.blur(); // Hide the keyboard after search
                }
            }
        }, 500);
    };

    // Perform the search operation
    const performSearch = async (query: string) => {

        // Check if the query is empty or contains only whitespace
        if (!query.trim()) {
            await fetchPosts(1); // Load all posts if the query is empty or whitespace
            return; // Exit the function without performing the search
        }

        setLoading(true);
        try {
            const result = await searchPosts(query);
            if (result === 'error') {
                throw new Error('Failed to search posts.');
            } else if (result === 'server-error') {
                throw new Error('Server error occurred during search.');
            } else {
                setPosts(result);
            }
        } catch (error) {
            console.error('Search error:', error);
            toast.error('Error searching posts.');
        } finally {
            setLoading(false);
        }
    };

    const handleCleanSearchQuery = async () => {
        setSearchQuery(''); // Clear the search query input
        setPosts([]); // Clear any filtered posts
        setCurrentPage(1); // Reset page to 1
        setIsFetching(false); // Ensure fetching state is reset
        setShouldFetch(true);
        // await fetchPosts(1); // Fetch posts again from the first page
    };

    useEffect(() => {
        if (shouldFetch) {
            fetchPosts(1); // Fetch the first page
            setShouldFetch(false); // Reset the flag after fetching
        }
    }, [shouldFetch]);

    // Toggle the search bar
    const handleSearchClick = () => {
        if (isSearchOpen) {
            // setSearchQuery('');
            // setSearchResults([]); // Clear search results when closing search
        }
        setIsSearchOpen(!isSearchOpen);
    };


    const toggleModal = () => {

        if (!isModalOpen) {
            // Track with Plausible
            if (typeof plausible !== "undefined") {
                plausible('Open Info Modal Homepage');
            }

            if (typeof window.sa_event !== 'undefined') {
                window.sa_event("open_info_modal_homepage");
            }

        }

        setIsModalOpen(!isModalOpen);
    };

    // Retry function for loading posts
    const retryLoading = () => {
        setRetryCount(prev => prev + 1); // Increment retry count, triggers useEffect
    };

    const fetchPosts = async (page: number) => {
        console.log(searchQuery && searchQuery.trim());
        if (searchQuery && searchQuery.trim()) {
            // Skip fetching more posts if a search query is active
            console.log("Search query is active. Skipping infinite scroll fetch.");
            return;
        }

        if (page === 1) {
            setLoading(true);
            setPosts([]); // Clear previous posts if this is a fresh load (new query or first page)
        } else {
            setIsLoadingMore(true);
        }
        setError(null);

        try {
            const result = await getAllPostsWithCreatorDetails(page, limit);
            if (result !== 'error' && result !== 'server-error') {
                const {posts: newPosts, currentPage: fetchedPage, totalPages} = result;
                setPosts((prevPosts) => [...prevPosts, ...newPosts]); // Concatenate new posts to the previous ones
                setCurrentPage(fetchedPage);
                setTotalPages(totalPages);
            } else {
                throw new Error('Failed to load posts.');
            }
        } catch (error) {
            console.error('Error fetching posts:', error);
            setError('An unexpected error occurred.');
        } finally {
            setLoading(false);
            setIsLoadingMore(false);
            setIsFetching(false); // Reset fetching state
        }
    };

    useEffect(() => {
        // Fetch posts whenever retry count changes
        fetchPosts(currentPage);
    }, [retryCount]);

    useEffect(() => {
        if (isOverlayOpen) {
            document.documentElement.classList.add('overlay-open');
        } else {
            document.documentElement.classList.remove('overlay-open');
        }

        return () => {
            document.documentElement.classList.remove('overlay-open');
        };
    }, [isOverlayOpen]);

    const handleViewPost = (homePost: HomePost) => {
        ReactGA.set({page: `/`});
        ReactGA.event({
            category: 'dish_interaction',
            action: 'dish_click',
            label: homePost.post.title,
            value: Number(homePost.post._id),
            nonInteraction: false,
            transport: 'xhr',
        });

        posthog.capture('dish_click', {
            category: 'dish_interaction',
            action: 'click',
            title: homePost.post.title,
            postId: homePost.post._id,
            path: window.location.pathname,
            username: homePost.creator.username,
        });

        // Track with Plausible
        if (typeof plausible !== "undefined") {
            plausible('Dish Click', {
                props: {
                    path: window.location.pathname,
                    dish_id: homePost.post._id.toString(),
                    dish: homePost.post.title,
                    creator: homePost.creator.username,
                }
            });
        }

        if (typeof window.sa_event !== 'undefined') {
            window.sa_event("dish_click", {
                dish_id: homePost.post._id.toString(),
                dish: homePost.post.title,
                creator: homePost.creator.username,
                path: window.location.pathname
            });
        } else {
            console.warn("Simple Analytics not initialized");
        }

        setSelectedPost(homePost);
        setIsViewing(true);
        setIsOverlayOpen(true);
    };

    const closeModal = () => {
        setIsViewing(false);
        setSelectedPost(null);
        setIsOverlayOpen(false);
        setIsOriginalImageLoaded(false); // Mark the original image as loaded
    };

    const getRelativeTime = (dateString: string | undefined) => {
        if (!dateString) return null;
        const date = new Date(dateString);
        return formatDistanceToNow(date, {addSuffix: true});
    };

    const noPostsAvailable = !posts || posts.length === 0;

    const handleReactionClick = async (username: string, postId: string | number) => {
        // Create a unique key for localStorage
        const localStorageKey = `${postId}_liked`; // the post id in home is already like this: creatorid_postid

        // Check if the user has liked the post (true means liked, false means not liked)
        const isLiked = localStorage.getItem(localStorageKey) === 'true';
        localStorage.setItem(localStorageKey, (!isLiked).toString()); // Toggle the boolean value

        // Determine if we are incrementing or decrementing
        const increment = !isLiked; // If not liked, we want to increment; if liked, we decrement

        if (!isLiked) {
            // Add animation class for this postId
            setAnimationClasses((prev) => ({
                ...prev,
                [postId]: 'fill-animation',
            }));

            // Remove animation class after animation duration
            setTimeout(() => {
                setAnimationClasses((prev) => ({
                    ...prev,
                    [postId]: '', // Reset the animation class
                }));
            }, 600); // 600ms to match the animation duration
        }

        // Update the post state with the new reaction count and toggle the like status
        setPosts(posts.map(post => {
            if (post.post._id !== postId) return post;

            // If reactionCount is undefined, default it to 0
            const currentCount = post.post.reactionCount || 0;

            // Ensure the reactionCount does not go below 0
            const newReactionCount = isLiked
                ? Math.max(currentCount - 1, 0) // Ensure it doesn't decrement below 0
                : currentCount + 1;

            // Return a new updated post object
            return {
                ...post,
                post: {
                    ...post.post,
                    reactionCount: newReactionCount // Make sure we're updating this deeply
                }
            };
        }));

        // Extract only the postId by splitting the combinedId (creatorid_postid)
        const actualPostId = (postId.toString()).split('_')[1]; // The second part is the actual postId
        // Send the reaction update to the backend
        const result = await updateReactionCount(username!, actualPostId.toString(), increment);

        if (result === true) {
            console.log('updated reaction count');
        } else {
            localStorage.setItem(localStorageKey, (isLiked).toString()); // Toggle the boolean value back in case it fails.
            console.error('Error updating reaction count in the backend');
            // Optionally, revert the optimistic UI update if backend fails
            setPosts(posts.map(post => {
                if (post.post._id !== postId) return post;

                // Revert the reaction count to its previous value
                const currentCount = post.post.reactionCount || 0;
                const revertedReactionCount = isLiked
                    ? currentCount + 1 // Undo decrement
                    : Math.max(currentCount - 1, 0); // Undo increment but ensure no negative values

                // Return a new updated post object
                return {
                    ...post,
                    post: {
                        ...post.post,
                        reactionCount: revertedReactionCount // Make sure we're updating this deeply
                    }
                };
            }));
        }
    };


    const handleGoogleMapsClick = (homePost: HomePost) => {
        if (typeof plausible !== "undefined") {
            plausible('Google Maps Click', {
                props: {
                    path: window.location.pathname,
                    dish_id: homePost.post._id?.toString(),
                    dish: homePost.post.title,
                    creator: homePost.creator.username,
                    restaurant: homePost.post.location ? homePost.post.location : ''
                }
            });
        }

        if (typeof window.sa_event !== 'undefined') {
            window.sa_event("google_maps_click", {
                path: window.location.pathname,
                dish_id: homePost.post._id?.toString(),
                dish: homePost.post.title,
                creator: homePost.creator.username,
                restaurant: homePost.post.location ? homePost.post.location : ''
            });
        }
    };

    return (
        <>
            <Helmet>
                <title>Solt | The place for foodies</title>
                <meta name="description" content={pageDescription}/>
                <link rel="canonical" href="https://solt.app/"/>
            </Helmet>

            <div className="home-container">
                <div className="home-header">
                    <div className="home-header-top">
                        <img src={soltLogo} alt="Solt logo" className="solt-logo"/>

                        {/* "Join as Creator" Button */}
                        {/* Conditionally render "Account" or "Join as Creator" based on login status */}
                        {(isAuthenticated) ?
                            (
                                <div>
                                    <a
                                        href="https://apps.apple.com/app/solt-the-app-for-foodies/id6450785241"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="ios-app-button-link"
                                    >
                                        <button className="join-creator-button">
                                            <img
                                                src={require('../assets/apple-icon.png')}
                                                alt="Apple Icon"
                                                className="apple-icon"
                                            />
                                            Download the app
                                        </button>
                                    </a>

                                    {/*<button*/}
                                    {/*    className="header-button"*/}
                                    {/*    onClick={toggleModal}*/}
                                    {/*>*/}
                                    {/*    About*/}
                                    {/*</button>*/}

                                    <button
                                        className="header-button"
                                        onClick={() => window.open(`/${username}`, '_self')}
                                    >
                                        Account
                                    </button>
                                </div>
                            ) : (
                                <div>
                                    <button
                                        className="header-button"
                                        onClick={toggleModal}
                                    >
                                        About
                                    </button>
                                    <a
                                        href="https://apps.apple.com/app/solt-the-app-for-foodies/id6450785241"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="ios-app-button-link"
                                    >
                                        <button className="join-creator-button">
                                            <img
                                                src={require('../assets/apple-icon.png')}
                                                alt="Apple Icon"
                                                className="apple-icon"
                                            />
                                            Download the app
                                        </button>
                                    </a>
                                </div>
                            )}
                    </div>
                </div>

                {/* Floating Modal Section */}
                <FloatingModal isOpen={isModalOpen} onClose={toggleModal}/>

                <div className={"bar"}/>


                {/* Search Icon and Input */}
                <div className="search-container">
                    <input
                        ref={searchInputRef} // Attach the ref here
                        type="text"
                        value={searchQuery}
                        onChange={handleSearchInputChange}
                        placeholder="Dishes, restaurants, cities..."
                        className={`search-input ${isSearchOpen ? 'open' : ''}`}
                    />
                    {/* Clean Query icon inside input field, only visible when there is a search term */}
                    {isSearchOpen && searchQuery && (
                        <img
                            src={closeIcon}
                            alt="Clean Query Icon"
                            className="clean-query-icon"
                            onClick={handleCleanSearchQuery}
                        />
                    )}

                    {/* Search or Exit icon outside input field */}
                    <img
                        src={searchIcon}
                        alt="Search Icon"
                        onClick={handleSearchClick}
                        className="search-icon"
                    />
                </div>

                <div className="title-with-icon">
                    <h2 className="home-post-title">
                        {searchQuery ? `Tasty finds for '${searchQuery}'` : "Must-try dishes from our foodies"}
                    </h2>
                </div>


                {/*<h2 className="post-title">Must-try dishes from our foodies</h2>*/}

                {/* "For You" Section with Floating Button */}
                {/*<div className="for-you-wrapper">*/}
                {/*    <div className="for-you-section">*/}
                {/*        <span className="for-you-text">Must-try dishes from our foodie creators</span>*/}
                {/*    </div>*/}
                {/*    <button className="floating-button" onClick={toggleModal}>*/}
                {/*        <img src={isModalOpen ? closeIcon : infoIcon} alt="Info Icon"/>*/}
                {/*    </button>*/}
                {/*</div>*/}


                {/* Loading spinner in the center */}
                {loading && <div className="loading-message"><ClipLoader size={50}/></div>}

                {loading && <div className="loading-message"><p>Loading posts...</p></div>}

                {error && (
                    <div className="error-message">
                        <p>{error}</p>
                        <button onClick={retryLoading} className="retry-btn">Load again</button>
                    </div>
                )}

                {!loading && !error && !noPostsAvailable ? (
                    <ResponsiveMasonry columnsCountBreakPoints={{1: 1, 350: 2, 900: 3}}>
                        <Masonry gutter="10px">
                            {posts.map((homePost) => {
                                    // Extract restaurant and city using the helper function
                                    const {
                                        restaurant,
                                        city,
                                        country
                                    } = extractLocationInfo(homePost.post.location || '');


                                    return (
                                        homePost && homePost.post && homePost.creator &&
                                        homePost.post.images && homePost.post.images.length > 0 && (
                                            <div key={homePost.post._id} className="post-card">


                                                <div className="profile-image-container">

                                                    {/* Skeleton loader */}
                                                    {/*<div*/}
                                                    {/*    className={`skeleton ${*/}
                                                    {/*        thumbnailLoadState[homePost.post._id] ? 'hidden' : ''*/}
                                                    {/*    }`}*/}
                                                    {/*></div>*/}


                                                    {/*<LazyLoadImage*/}
                                                    {/*    src={homePost.post.images[0]}*/}
                                                    {/*    alt={homePost.post.title}*/}
                                                    {/*    rel="preload"*/}
                                                    {/*    effect="blur" // Adds a blur effect while loading*/}
                                                    {/*    className="post-image"*/}
                                                    {/*    onClick={() => handleViewPost(homePost)} // Keep existing click handler*/}
                                                    {/*/>*/}


                                                    {!thumbnailLoadState[homePost.post._id] && (
                                                        <div className="skeleton"></div>
                                                    )}

                                                    {/* Lazy-loaded thumbnail */}
                                                    <img
                                                        src={homePost.post.images[0]}
                                                        alt={homePost.post.title}
                                                        // effect="blur" // Adds a blur effect while loading
                                                        className={`post-image ${
                                                            thumbnailLoadState[homePost.post._id] ? '' : 'hidden'
                                                        }`} // Hide thumbnail until loaded
                                                        onLoad={() => handleThumbnailLoad(homePost.post._id)} // Mark this post's thumbnail as loaded
                                                        onClick={() => handleViewPost(homePost)} // Keep existing click handler
                                                    />

                                                    {/*<div className="dish-title-overlay">*/}
                                                    {/*    <h3>{homePost.post.title}</h3>*/}
                                                    {/*</div>*/}

                                                    {/* Single Reaction positioned bottom-right */}
                                                    <div className="single-reaction-overlay">
                                                        <div
                                                            className="reaction-item"
                                                            onClick={(e) => {
                                                                e.stopPropagation(); // Prevents the image click event
                                                                handleReactionClick(homePost.creator.username, homePost.post._id); // Handle reaction click separately
                                                            }}
                                                        >
                                                            <img
                                                                src={localStorage.getItem(`${homePost.post._id}_liked`) === 'true' ? likedReactionIcon : normalReactionIcon}
                                                                alt="Reaction Icon"
                                                                className={`reaction-icon ${animationClasses[homePost.post._id] || ''}`} // Use animation state instead of post object
                                                            />
                                                            <span
                                                                className="reaction-number">{homePost.post.reactionCount || 0}</span> {/* Fallback to 0 if missing */}

                                                            {/*<div className="creator-info">*/}
                                                            {/*    <img*/}
                                                            {/*        src={homePost.creator.profileImage || './profile_image_placeholder.png'}*/}
                                                            {/*        alt={homePost.creator.username}/>*/}
                                                            {/*</div>*/}

                                                        </div>
                                                    </div>

                                                </div>

                                                <div className="post-info">
                                                    {/*<h3>{homePost.post.title}</h3>*/}
                                                    <div className="post-info-header">
                                                        <h3
                                                            className="post-title clickable"
                                                            onClick={() => handleViewPost(homePost)}
                                                        >
                                                            {homePost.post.title}
                                                        </h3>
                                                        {homePost.post.ratings?.dishRating && (
                                                            <div className="post-rating">
                                                                <img src={starIcon} alt="Star Icon"
                                                                     className="review-star-icon"/> {/* Replace with actual star icon path */}
                                                                <span>{Number(homePost.post.ratings.dishRating || 0).toFixed(2)}</span>
                                                            </div>
                                                        )}
                                                    </div>


                                                    {/*{homePost.post.location && <p>{homePost.post.location}</p>}*/}
                                                    {/*{homePost.post.location && (*/}
                                                    {/*    <div className="card-location-container">*/}
                                                    {/*        <img src={restaurantPlaceIcon} alt="Location Icon"*/}
                                                    {/*             className="card-location-icon"/>*/}

                                                    {/*        /!*<p className="location-text">{homePost.post.location}</p>*!/*/}
                                                    {/*        <p className="card-location-text"> {restaurant}, {city}, {country} </p>*/}
                                                    {/*        /!*<span>⭐4.5 (201)</span>*!/*/}
                                                    {/*        /!*<p className="location-city">{city}</p>*!/*/}

                                                    {/*        /!*<div className="rating">*!/*/}
                                                    {/*        /!*    <span>⭐ 4.5 (201)</span> /!* Mock rating for demonstration *!/*!/*/}
                                                    {/*        /!*</div>*!/*/}
                                                    {/*    </div>*/}
                                                    {/*)}*/}
                                                    {(homePost.post.location_english_formatted || homePost.post.location) && (
                                                        <div className="card-location-container">
                                                            <img src={restaurantPlaceIcon} alt="Location Icon"
                                                                 className="card-location-icon"/>
                                                            <p className="card-location-text">
                                                                {homePost.post.location_english_formatted
                                                                    ? homePost.post.location_english_formatted
                                                                    : `${restaurant}, ${city}, ${country}`}
                                                            </p>
                                                        </div>
                                                    )}


                                                    {/*<div className="creator-info">*/}
                                                    {/*    <img*/}
                                                    {/*        src={homePost.creator.profileImage || './profile_image_placeholder.png'}*/}
                                                    {/*        alt={homePost.creator.username}/>*/}
                                                    {/*    <span>@{homePost.creator.username}</span>*/}
                                                    {/*</div>*/}

                                                    {homePost.post.created &&
                                                        <div className="post-card-footer">
                                                            <div className="creator-info">
                                                                <img
                                                                    src={homePost.creator.profileImage || './creator_profile_image_placeholder.png'}
                                                                    alt={homePost.creator.username}/>
                                                                <div className="creator-info-text">
                                                                    <span
                                                                        style={{fontWeight: 400}}>Recommended by&nbsp;</span>
                                                                    <span><Link
                                                                        to={`/${homePost.creator.username}`}>{homePost.creator.username}</Link></span>
                                                                    <span>&nbsp;</span>
                                                                    <span
                                                                        style={{fontWeight: 400}}>and others</span>
                                                                    {/*<span>others</span>*/}
                                                                </div>
                                                                {/*<span*/}
                                                                {/*    style={{fontWeight: 500}}>{getRelativeTime(homePost.post.created)}</span>*/}

                                                            </div>
                                                            {/*<span>{getRelativeTime(homePost.post.created)}</span>*/}
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        )
                                    )
                                }
                            )}
                        </Masonry>
                    </ResponsiveMasonry>
                ) : (
                    !loading && !error && <div className="no-posts-message"><p>No posts available.</p></div>
                )}


                {/*/!* "Show more" button *!/*/}
                {/*{!searchQuery && currentPage < totalPages && (*/}
                {/*    <button className="show-more-button" onClick={handleShowMore} disabled={isLoadingMore}>*/}
                {/*        {isLoadingMore ? 'Loading...' : 'Show more'}*/}
                {/*    </button>*/}
                {/*)}*/}

                {/* Loading indicator for fetching more posts */}
                {isFetching && <div className="loading-more-message"><ClipLoader size={30}/></div>}

                <BottomModal isOpen={isViewing} onClose={closeModal}>
                    {selectedPost && (
                        <div className="home-overlay-content">
                            <div className="modal-bar"></div>
                            {/* Add this line */}

                            <div className="home-fixed-image-box">
                                {/* Conditionally render the compressed or original image */}

                                {/*<img*/}
                                {/*    src={selectedPost.post.images[0]} // Compressed image*/}
                                {/*    alt={`${selectedPost.post.title} (compressed)`}*/}
                                {/*    className={`home-fixed-image ${isOriginalImageLoaded ? 'hidden' : ''}`} // Hide compressed image once original is loaded*/}
                                {/*/>*/}

                                {/* Display the original image if preloaded */}
                                <img
                                    src={
                                        selectedPost.post.images[0].includes('_COMPRESSED.webp')
                                            ? selectedPost.post.images[0].replace('_COMPRESSED.webp', '_ORIGINAL.jpeg')
                                            : selectedPost.post.images[0] // Fallback to original URL if no match
                                    }
                                    alt={selectedPost.post.title}
                                    rel="preload"
                                    className={`home-fixed-image`} // Initially hidden until loaded
                                    onLoad={() => {
                                        setIsOriginalImageLoaded(true); // Mark the original image as loaded
                                    }}
                                    onError={() => {
                                        console.error('Failed to load original image');
                                    }}
                                />

                                {/* Show a loading spinner while the original image is loading */}
                                {!isOriginalImageLoaded && (
                                    <div className="loading-message">
                                        <ClipLoader size={40}/> {/* Loading spinner */}
                                    </div>
                                )}

                            </div>

                            {/*<div>{selectedPost.post.images[0].includes('_COMPRESSED.webp') ? "D" : "N"}</div>*/}
                            {/* Container for title and rating */}
                            <div className="overlay-title-rating-container">
                                <h3 className="overlay-post-title">{selectedPost.post.title}</h3>
                                {selectedPost.post.ratings?.dishRating && (
                                    <div className="overlay-post-rating">
                                        <img src={starIcon} alt="Star Icon" className="overlay-review-star-icon"/>
                                        <span>{Number(selectedPost.post.ratings.dishRating).toFixed(2)}</span>
                                    </div>
                                )}
                            </div>

                            {selectedPost.post.created && (
                                <div className="creator-info-modal">
                                    <Link to={`/${selectedPost.creator.username}`}>
                                        <img
                                            src={selectedPost.creator.profileImage || './creator_profile_image_placeholder.png'}
                                            alt={selectedPost.creator.username}
                                            className="creator-profile-image-modal"
                                        />
                                    </Link>
                                    <span>
                                        Added by{' '}
                                        <Link to={`/${selectedPost.creator.username}`}
                                              className="no-style-link" style={{fontWeight: 'bold'}}>
                                            {selectedPost.creator.username}
                                         </Link>{' '}
                                        {getRelativeTime(selectedPost.post.created)}
                                    </span>
                                </div>
                            )}

                            <div className="bar"></div>

                            <div className="info-card">
                                <h4>Where to eat:</h4>
                                <div className="location-container">
                                    <p className="location-text">{selectedPost.post.location_english_formatted || selectedPost.post.location}</p>
                                    <a
                                        href={
                                            selectedPost.post.google_place_id
                                                ? `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(selectedPost.post.location || "Place Name")}&query_place_id=${selectedPost.post.google_place_id}`
                                                : `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(selectedPost.post.location || "")}`
                                        }
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="google-maps-icon"
                                        onClick={() => handleGoogleMapsClick(selectedPost)} // Track Google Maps Click
                                    >
                                        <img src={redirectIcon} alt="Google Maps"/>
                                    </a>
                                </div>
                            </div>


                            {selectedPost.post.description && <div className="bar"></div>}

                            {selectedPost.post.description && (
                                <div className="info-card">
                                    <h4>Description:</h4>
                                    <p className="description-text">{selectedPost.post.description}</p>
                                </div>
                            )}

                            {/*<div className="buttons-container">*/}
                            {/*    <button className="form-submit-btn">*/}
                            {/*        <a href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(selectedPost.post.location || "")}`}*/}
                            {/*           target="_blank" rel="noopener noreferrer"*/}
                            {/*           style={{textDecoration: 'none', color: 'white'}}>*/}
                            {/*            Open in Google Maps*/}
                            {/*        </a>*/}
                            {/*    </button>*/}
                            {/*    /!*<button onClick={closeModal} className="form-cancel-btn">Close</button>*!/*/}
                            {/*</div>*/}
                        </div>
                    )}
                </BottomModal>

                <ToastContainer
                    autoClose={1000}
                    theme="colored"
                    draggable
                    hideProgressBar
                    closeButton={false}
                    newestOnTop
                />
            </div>

            <div className="home-footer">
                <div className={"bar"}/>
                <button className="footer-button"
                        onClick={() => window.open('https://solt.app/creators', '_blank')}>
                    Become a foodie creator on Solt
                </button>
                <button className="footer-button"
                        onClick={() => window.open('https://solt.app/blog', '_blank')}>
                    Blog
                </button>
            </div>
        </>
    );
};

export default Home;
