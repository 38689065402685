import React, { useState, useEffect } from "react";
import { MapPin, Loader } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async"; // Import Helmet for metadata
import blogPostData from "../assets/blogPostData.json";
import soltImage from "../assets/soltBolgPoster.jpeg";
import "./BlogPage.css";
import soltLogo from "../assets/logo.png";

interface BlogPost {
  id: number;
  post: {
    title: string;
    image: string;
    location: string;
    googlePlaceId: string;
  };
  analysis: {
    Page: string;
    Header: {
      DishName: string;
      FeaturedImage: string;
      RatingAndLikes: string;
    };
    ContentSections: {
      Overview: {
        Cuisine: string;
        AvailableAt: string;
        IdealFor: string;
      };
      DishHighlights: string[];
      WhereToTryIt: {
        ServedAt: string;
        Atmosphere: string;
      };
      RelatedDishes: string[];
      CustomerReviews: {
        UserReviews: string[];
      };
    };
  };
  UrlTag: string;
}

const BlogPage: React.FC = () => {
  const [posts, setPosts] = useState<BlogPost[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    setPosts(blogPostData as BlogPost[]);
    setIsLoading(false);
  }, []);

  const handlePostClick = (urlTag: string) => {
    navigate(`/blog/${urlTag}`);
  };

  // Metadata for the blog page
  const pageTitle = "Explore Food Stories and Reviews | Solt Blog - the place for foodies";
  const pageDescription =
    "Discover delicious food reviews, stories, and insights about culinary experiences around the world on Solt's blog.";
  const canonicalUrl = "https://solt.app/blog";

  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="flex flex-col items-center gap-4">
          <Loader className="w-8 h-8 animate-spin text-blue-600" />
          <p className="text-gray-600">Loading content...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="blog-page">
      {/* Helmet for SEO */}
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>

      {/* Top Navigation */}
      <div className="top-navigation">
        <div className="nav-content">
          <a href="/">
            <img src={soltLogo} alt="Solt logo" className="solt-logo" />
          </a>
          <div className="header-buttons">
            <a
              href="https://apps.apple.com/app/solt-the-app-for-foodies/id6450785241"
              target="_blank"
              rel="noopener noreferrer"
              className="ios-app-button-link"
              aria-label="Download the app"
            >
              <button className="join-creator-button">
                <img
                  src={require("../assets/apple-icon.png")}
                  alt="Apple Icon"
                  className="apple-icon"
                />
                Download the app
              </button>
            </a>
          </div>
        </div>
      </div>

      {/* Hero Section */}
      <div className="hero">
        <img src={soltImage} alt="Featured" className="hero-image" />
      </div>

      {/* Blog Posts Grid */}
      <div className="blog-grid-container">
        <div className="blog-grid">
          {posts.map((post) => (
            <div
              key={post.id}
              className="blog-item"
              onClick={() => handlePostClick(post.UrlTag)}
            >
              <img
                src={post.post.image}
                alt={post.analysis.Header.DishName}
                className="blog-page-image"
              />

              <div className="blog-title-container">
                <h3 className="blog-title">{post.analysis.Header.DishName}</h3>
              </div>

              <p className="blog-description">
                {post.analysis.ContentSections.DishHighlights[0]}
              </p>

              <div className="blog-location">
                <MapPin className="map-icon" />
                <span>{post.post.location}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BlogPage;
