import React from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import './TCandPPModal.css'; // Add your modal styling

interface TCAndPPModalProps {
    isOpen: boolean;
    onClose: () => void;
    onAccept: () => Promise<void>;
    loading: boolean;
}

const TCAndPPModal: React.FC<TCAndPPModalProps> = ({ isOpen, onClose, onAccept, loading }) => {
    if (!isOpen) return null;

    return (
        <div className="tc-modal-overlay">
            <div className="tc-modal-content">
                <h2>Legal Updates</h2>
                <p>
                    We have recently updated our <strong>Terms of Service</strong>, <strong>Privacy Policy</strong>,
                    and <strong>Cookie Policy</strong>.
                </p>
                <p>
                    By continuing to use Solt, you acknowledge that you have read and agree to these updated policies.
                </p>
                <div className="tc-modal-buttons">
                    <button className="decline-button" onClick={onClose} disabled={loading}>
                        {loading ? <ClipLoader color="#fff" size={20} /> : 'Decline'}
                    </button>
                    <button className="accept-button" onClick={onAccept} disabled={loading}>
                        {loading ? <ClipLoader color="#fff" size={20} /> : 'Accept'}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default TCAndPPModal;
