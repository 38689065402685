import React from 'react';
import './Privacy.css';

const Privacy: React.FC = () => {
    return (
        <>
            <head>
                <title>Solt - The place for foodies and food reviewers</title>
                <meta
                    name="description"
                    content="Explore the best dishes at top restaurants, handpicked by food content creators. Discover unique flavors, see where to eat them, and share your own foodie favorites."
                />
            </head>
            <div style={{padding: '2rem'}}>
                <h1>Privacy Policy</h1>
                <p>Our Privacy Policy was last updated on <strong>20 December 2024</strong>.</p>
                <p>
                    Welcome to Solt! This Privacy Policy describes how Jerry Technologies SRL (“Solt,” “we,” “our,” or
                    “us”) collects,
                    uses, shares, and protects your personal data when you use our website, mobile applications, or
                    related services
                    (collectively, the “Services”).
                </p>
                <p>
                    Please read this Privacy Policy carefully to understand our practices regarding your personal data.
                    By using the
                    Services, you agree to the terms of this Privacy Policy.
                </p>
                <p>
                    Solt reserves its right to change and update at any time the content of this website and app, as
                    well as the Privacy
                    Policy and the General Terms of Use, without any other prior notification. Therefore, you should
                    visit this section
                    regularly to review the privacy policy that you have agreed to comply with.
                </p>

                <h2>1. Information We Collect</h2>
                <p>We may collect the following types of information when you interact with our Services:</p>

                <h3>1.1. Information You Provide</h3>
                <ul>
                    <li>
                        <strong>Account Information:</strong> Name, email address, and other registration details.
                    </li>
                    <li>
                        <strong>Content:</strong> Images, reviews, text, recommendations, or other materials or
                        information uploaded by you.
                    </li>
                    <li>
                        <strong>Payment Information:</strong> If applicable, payment details provided for premium
                        features or subscriptions.
                    </li>
                </ul>

                <h3>1.2. Information We Automatically Collect</h3>
                <ul>
                    <li>
                        <strong>Device Information:</strong> Information about the device you use to access the
                        Services, such as the name of the device, operating system, device identifiers, browser type and
                        settings, and your connection information. The information collected may depend on the type of
                        device you use and its settings.
                    </li>
                    <li>
                        <strong>Usage Data:</strong> Information about your use of the Services, such as the types of
                        content you view or engage with, the features you use, actions you take, your time zone,
                        country, the dates and times of access, user agent and version, type of computer or mobile
                        device, and your connection information.
                    </li>
                    <li>
                        <strong>Log Data:</strong> Information automatically collected when you interact with the
                        Services,
                        such as your Internet Protocol (IP) address, browser type and settings, the date and time of
                        your
                        request, and how you interact with the Services.
                    </li>
                    <li>
                        <strong>Cookies and Tracking Technologies:</strong> Data collected through cookies, web beacons,
                        pixels, and
                        similar technologies to track your preferences, improve the Services, and deliver personalized
                        experiences.
                    </li>
                    <li>
                        <strong>Location Data:</strong> General location data inferred from your IP address or device
                        settings to improve the product experience and for security purposes, such as detecting unusual
                        login activity. In some cases, we may request more precise location information from your
                        device, such as GPS data, to enhance functionality.
                    </li>
                    <li>
                        <strong>Other Voluntarily Provided Information:</strong> Any other information you provide to
                        us, such as through
                        forms, surveys, feedback, or customer support communications.
                    </li>
                </ul>

                <h3>1.3. Information from Third Parties</h3>
                <ul>
                    <li>
                        <strong>Analytics Tools:</strong> Aggregated and/or anonymized data from tools like Metabase,
                        Google Analytics,
                        Hotjar, and similar platforms.
                    </li>
                    <li>
                        <strong>Third-Party Accounts:</strong> If you connect your account to a third-party service
                        (e.g., social media
                        platforms), we may collect publicly available profile information and any data you allow the
                        service to share with us.
                    </li>
                    <li>
                        <strong>Marketing and Advertising Platforms:</strong> Information about your interactions with
                        advertisements
                        served on our behalf by third parties, including ad impressions, clicks, and conversions.
                    </li>
                    <li><strong>Publicly Available Information:</strong> Information collected from publicly available
                        sources, such as content published on the internet, to develop and improve our Services.
                    </li>
                </ul>

                <h3>1.4. Other Data</h3>
                <p>
                    We may collect other types of information that are necessary for the operation of our Services,
                    compliance with legal
                    requirements, or as otherwise described at the time of collection.
                </p>


                <h2>2. How We Use Your Information</h2>
                <p>We may use your personal data for various purposes, including but not limited to:</p>

                <h3>2.1. To Provide and Enhance Our Services</h3>
                <ul>
                    <li>Operate, maintain, and improve the functionality, performance, and usability of the Services.
                    </li>
                    <li>Personalize your experience by understanding your preferences and behaviors.</li>
                    <li>Facilitate account creation, authentication, and secure access to our Services.</li>
                    <li>Answer requests for customer or technical support.</li>
                    <li>Communicate with you about your use of our Services (your account, content, policy changes,
                        community updates).
                    </li>
                    <li>Solve technical issues or errors while using our Services.</li>
                </ul>

                <h3>2.2. For Analytics and Insights</h3>
                <ul>
                    <li>Analyze user behavior, interactions, and trends to identify areas for improvement.</li>
                    <li>Use data from analytics tools to optimize features, design, and content.</li>
                    <li>Conduct research and analysis for internal business purposes.</li>
                </ul>

                <h3>2.3. For Business Operations</h3>
                <ul>
                    <li>Process transactions, such as payments or subscriptions, and manage billing-related inquiries.
                    </li>
                    <li>Communicate with you about updates, new features, changes to the Services, or customer support
                        requests.
                    </li>
                    <li>Manage and administer promotions, surveys, or feedback mechanisms.</li>
                </ul>

                <h3>2.4. For Marketing and Advertising</h3>
                <ul>
                    <li>
                        Deliver promotional communications, offers, and recommendations, tailored to your preferences
                        and usage
                        patterns. You may opt out of such communications at any time.
                    </li>
                    <li>Support partnerships and growth initiatives through aggregated and anonymized data insights.
                    </li>
                    <li>Display relevant advertisements on our Services or third-party platforms.</li>
                </ul>

                <h3>2.5. For Legal, Security, and Compliance</h3>
                <ul>
                    <li>Detect, investigate, and prevent fraudulent activities, unauthorized access, or other harmful
                        behaviors.
                    </li>
                    <li>Protect the rights, property, or safety of our users, employees, and partners.</li>
                    <li>Comply with applicable laws, regulations, industry standards, or legal processes.</li>
                </ul>

                <h3>2.6. For Other Purposes</h3>
                <ul>
                    <li>Fulfill any purpose for which you have provided explicit consent.</li>
                    <li>
                        Carry out any other legitimate business purposes disclosed to you at the time of data collection
                        or as otherwise
                        required for the operation of the Services.
                    </li>
                </ul>

                <h3>2.7. Legal Basis for Processing</h3>
                <p>
                    If you are located in the European Economic Area (EEA), Switzerland, or the United Kingdom, we
                    process your personal data based on the following legal grounds:
                </p>
                <ul>
                    <li>
                        <strong>Consent:</strong> When you have explicitly given your consent for specific purposes,
                        such as receiving marketing communications or using cookies for analytics.
                    </li>
                    <li>
                        <strong>Performance of a Contract:</strong> When processing is necessary to provide the Services
                        you have requested, such as creating and managing your account or fulfilling transactions.
                    </li>
                    <li>
                        <strong>Legal Obligations:</strong> When processing is required to comply with legal
                        obligations, such as financial or tax regulations.
                    </li>
                    <li>
                        <strong>Legitimate Interests:</strong> To analyze user behavior, improve our Services, ensure
                        the security of our platform, or communicate with you about updates, provided these interests
                        are not overridden by your privacy rights.
                    </li>
                </ul>
                <p>
                    If you have questions about the legal basis for processing specific types of data, please contact us
                    at <a href="mailto:hello@solt.app">hello@solt.app</a>.
                </p>


                <h2>3. How We Share Your Information</h2>
                <p>We may share your personal data in the following circumstances:</p>

                <h3>3.1. With Service Providers</h3>
                <ul>
                    <li>
                        With third-party vendors and service providers who perform functions on our behalf, such as
                        hosting, analytics,
                        payment processing, customer support, marketing, or communication services.
                    </li>
                    <li>
                        These providers are only given the information necessary to perform their specific tasks and are
                        obligated to
                        protect your data.
                    </li>
                </ul>

                <h3>3.2. For Business Operations and Partnerships</h3>
                <ul>
                    <li>To facilitate business partnerships, collaborations, or co-marketing initiatives.</li>
                    <li>
                        We may share aggregated, anonymized, or otherwise de-identified data for research, development,
                        or operational
                        purposes.
                    </li>
                </ul>

                <h3>3.3. During Business Changes</h3>
                <p>
                    In connection with a merger, acquisition, reorganization, sale of assets, or other corporate
                    transaction, your data
                    may be transferred to the relevant entity, subject to this Privacy Policy. In the event of such a
                    transaction, we
                    reserve the right to transfer or assign the information we have collected as part of the
                    transaction, including
                    personal data, to a new legal entity or organization. This ensures continuity of the Services and
                    compliance with
                    applicable data protection regulations.
                </p>

                <h3>3.4. To Comply with Legal Obligations</h3>
                <ul>
                    <li>
                        When required to do so by law, regulation, or legal process, such as responding to subpoenas,
                        court orders, or
                        government requests.
                    </li>
                    <li>
                        To protect our rights, property, or safety, or that of our users, employees, or partners.
                    </li>
                </ul>

                <h3>3.5. With Your Consent</h3>
                <p>
                    If you explicitly agree to share your data with other parties for specific purposes, we will only
                    share it in
                    accordance with the consent provided.
                </p>

                <h3>3.6. For Other Legitimate Business Purposes</h3>
                <p>
                    To fulfill any other purpose disclosed to you at the time of data collection or as reasonably
                    necessary to operate
                    and improve our Services.
                </p>

                <h3>3.7. With Employees and Collaborators</h3>
                <p>
                    <strong>Purpose of Sharing:</strong> We may share your personal data with our employees,
                    contractors, freelancers,
                    or other collaborators on a need-to-know basis to perform tasks related to the operation,
                    maintenance, and
                    improvement of the Services.
                </p>

                <h2>4. Data Security</h2>
                <p>
                    We take reasonable measures to protect your personal data from unauthorized access, loss, or misuse.
                    These measures
                    include encryption, firewalls, and secure servers. However, no method of transmission or storage is
                    100% secure,
                    and we cannot guarantee absolute security.
                </p>

                <h2>5. Data Retention</h2>
                <p>
                    We retain your personal data for as long as necessary to provide the Services or fulfill the
                    purposes outlined in
                    this Privacy Policy. User-generated content may be retained indefinitely for operational, marketing,
                    or legal
                    purposes, even after account termination.
                </p>


                <h2>6. Your Rights</h2>
                <p>Depending on your location, you may have the following rights:</p>
                <ul>
                    <li>
                        <strong>Access and Correction:</strong> Request access to your personal data or correct
                        inaccuracies.
                    </li>
                    <li>
                        <strong>Deletion:</strong> Request the deletion of your personal data, subject to legal or
                        operational obligations.
                    </li>
                    <li>
                        <strong>Data Portability:</strong> Receive a copy of your data in a portable format.
                    </li>
                    <li>
                        <strong>Opt-Out:</strong> Manage cookie preferences or unsubscribe from promotional
                        communications.
                    </li>
                </ul>
                <p>
                    To exercise your rights, please contact us at <a href="mailto:hello@solt.app">hello@solt.app</a>.
                </p>

                <h3>Do Not Sell My Personal Information</h3>
                <p>
                    If you are a resident of California, you have the right to opt out of the sale of your personal
                    information under the California Consumer Privacy Act (CCPA).
                </p>
                <p>
                    Solt does not currently sell personal information as defined under the CCPA. However, if you have
                    questions or would like to exercise your rights, please contact us at
                    <a href="mailto:hello@solt.app">hello@solt.app</a>.
                </p>
                <p>
                    For more information about your rights under the CCPA, please see our <a
                    href="https://www.solt.app/terms">Terms of Service</a>.
                </p>


                <h2>7. Cookies and Tracking Technologies</h2>
                <p>
                    We use cookies and similar technologies to enhance user experience and analyze usage patterns. You
                    can manage cookie preferences through your browser settings or opt out of tracking in regions where
                    required by law.
                    If you use our Services without creating an account, some of the data described above may still be
                    stored using cookies or similar technologies to maintain your preferences across browsing sessions.
                </p>
                <p>
                    You can manage your data collection preferences as follows:
                </p>
                <ul>
                    <li><strong>For Websites:</strong> Adjust your browser settings to block or clear cookies, or use
                        tools like the <a href="https://tools.google.com/dlpage/gaoptout" target="_blank">Google
                            Analytics Opt-Out Add-On</a>.
                    </li>
                    <li><strong>For Mobile Apps:</strong> Change your device settings to disable tracking (e.g., "Allow
                        Apps to Request to Track" on iOS or "Opt out of Ads Personalization" on Android).
                    </li>
                </ul>
                <p>
                    Please note that disabling tracking or cookies may impact certain features of our Services.
                </p>

                <h2>8. Third-Party Services</h2>
                <p>
                    Our Services may integrate with or link to third-party platforms (e.g., analytics tools, payment
                    processors). We
                    are not responsible for the privacy practices of these third parties. Please review their policies
                    to understand
                    how they handle your data.
                </p>

                <h2>9. International Data Transfers</h2>
                <p>
                    If you access the Services outside Romania, your data may be transferred to and processed in Romania
                    or other
                    countries where we or our service providers operate. By using the Services, you consent to such
                    transfers.
                </p>
                <h3>9.1. Data Storage and Processing</h3>
                <p>
                    Your personal data is primarily stored and processed within the European Union (EU), including but
                    not limited to data centers in Belgium and Germany. However, some of your data may also be
                    transferred to and processed in the United States or other countries outside the EU, depending on
                    the tools and services we use (e.g., analytics or cloud service providers).
                </p>
                <p>
                    While we strive to work with service providers that prioritize data security and privacy, we may not
                    have full control over all aspects of data processing performed by these third-party providers. By
                    using our Services, you acknowledge and consent to your data being processed in these locations,
                    which may have different data protection standards than those in your country of residence.
                </p>
                <p>
                    We take reasonable measures to ensure that your data is protected regardless of where it is stored
                    or processed, including compliance with applicable data protection laws and contractual safeguards,
                    where possible. However, we cannot guarantee the same level of protection in all jurisdictions.
                </p>
                <p>
                    If you have concerns about the location or processing of your personal data, please contact us at <a
                    href="mailto:hello@solt.app">hello@solt.app</a>.
                </p>

                <h2>10. Children’s Privacy</h2>
                <p>
                    The Services are not intended for users under 16 years old. If we learn that we have collected data
                    from a user
                    under the required minimum age, we will delete it promptly.
                </p>

                <h2>11. Changes to This Privacy Policy</h2>
                <p>
                    We may update this Privacy Policy from time to time. If changes are made, we will notify you by
                    posting the
                    updated policy on our Site or App. Your continued use of the Services after the updates constitutes
                    acceptance
                    of the revised policy.
                </p>

                <h2>12. Contact Information</h2>
                <p>If you have questions or concerns about this Privacy Policy, please contact us at:</p>
                <ul>
                    <li>Email: <a href="mailto:hello@solt.app">hello@solt.app</a></li>
                    <li>Website: <a href="https://www.solt.app" target="_blank"
                                    rel="noopener noreferrer">www.solt.app</a></li>
                </ul>

            </div>
        </>
    );
};

export default Privacy;
