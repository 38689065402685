import React from 'react';

const Terms: React.FC = () => {
    return (
        <>
            <head>
                <title>Solt - The place for foodies and food reviewers</title>
                <meta
                    name="description"
                    content="Explore the best dishes at top restaurants, handpicked by food content creators. Discover unique flavors, see where to eat them, and share your own foodie favorites."
                />
            </head>
            <div style={{padding: '2rem'}}>
                <h1>Terms of Service</h1>
                <p>
                    Our Terms of Service were last updated on <strong>7 January 2025</strong>.
                </p>
                <p>
                    Welcome to Solt! These Terms of Service (“Terms”) govern your use of the website located at{' '}
                    <a href="https://www.solt.app">www.solt.app</a> (the “Site”), our mobile applications (“App”), and
                    any related services provided by Jerry Technologies SRL (“Solt,” “we,” “our,” or “us”).
                    Collectively,
                    the Site, App, and services are referred to as the “Services.”
                </p>
                <p>
                    Please read these Terms and our{' '}
                    <a href="https://www.solt.app/privacy" target="_blank" rel="noopener noreferrer">
                        Privacy Policy
                    </a>{' '}
                    carefully, as they govern your use of the Services.
                </p>

                <h2>1. Agreement to Terms</h2>
                <p>
                    These Terms govern your use of the Services from the moment you create an account, access the
                    Services,
                    or otherwise interact with the Services. These Terms set out the rights and obligations of all users
                    regarding the use of the Service.
                </p>
                <p>
                    Your access to and use of the Services is conditioned on your acceptance of and compliance with
                    these Terms.
                    By creating an account or accessing our Services in any capacity, you agree to be bound by these
                    Terms.
                </p>
                <p>
                    If you do not agree with any part of these Terms, do not access or use the Services.
                </p>
                <p>
                    If you are accessing or using the Services on behalf of a company (such as your employer) or other
                    legal entity,
                    you represent and warrant that you have the authority to bind that entity to these Terms. In such
                    cases, “you”
                    and “your” refer to that entity.
                </p>
                <p>
                    Your access to and use of the Services is also conditioned on your acceptance of and compliance with
                    the
                    Privacy Policy of the Services. Our Privacy Policy describes our policies and procedures on the
                    collection,
                    use and disclosure of your personal information when you use the Services and tells you about your
                    privacy
                    rights and how the law protects you. Please read our{' '}
                    <a href="https://www.solt.app/privacy" target="_blank" rel="noopener noreferrer">
                        Privacy Policy
                    </a>{' '}
                    carefully before using our Services.
                </p>
                <h3>Updates to Terms</h3>
                <p>
                    We reserve the right to update or replace these Terms at our discretion, at any time. If a revision
                    is material,
                    we will make reasonable efforts to provide at least 30 days’ notice (through the Services, email, or
                    other
                    reasonable means) prior to any Terms taking effect. What constitutes a material change will be
                    determined
                    at our sole discretion.
                </p>
                <p>
                    By continuing to access or use the Services after those revisions become effective, constitutes
                    acceptance of
                    the updated Terms and you agree to be bound by them. If you do not agree to the new Terms, in whole
                    or in part,
                    please stop using the Services.
                </p>


                <h2>2. Definitions</h2>
                <ul>
                    <li>
                        <strong>Solt, We, Us:</strong> Refers to Jerry Technologies SRL, a company registered in
                        Romania, with
                        registration number: RO46294133.
                    </li>
                    <li>
                        <strong>User, You, Your:</strong> Refers to any individual or entity that accesses, registers
                        for, or uses the
                        Services, or the company or any other legal entity on behalf of which such individual is
                        accessing or using the
                        Services, as applicable. Users may include:
                        <ul>
                            <li><strong>Creators:</strong> Users who upload, share, or otherwise create content on the
                                Services.
                            </li>
                            <li><strong>Consumers:</strong> Users who consume content or interact with the Services
                                without uploading or creating content.
                            </li>
                        </ul>
                        Regardless of their role as a creator or consumer, all Users are subject to these Terms.
                    </li>
                    <li><strong>Site:</strong> Refers to the website located at <a
                        href="https://www.solt.app">www.solt.app</a>.
                    </li>
                    <li>
                        <strong>Application/App:</strong> Refers to our mobile applications available on supported
                        platforms. Means the
                        software program provided by Us downloaded by You on any electronic device.
                    </li>
                    <li>
                        <strong>Application Store/App Store:</strong> Means the digital distribution service operated
                        and developed by
                        Apple Inc. (Apple App Store) or Google Inc. (Google Play Store) in which the Application has
                        been downloaded.
                    </li>
                    <li>
                        <strong>Account:</strong> Means a unique account created for You to access our Services or parts
                        of our
                        Services.
                    </li>
                    <li>
                        <strong>Device:</strong> Means any device that can access the Services such as a computer, a
                        cell phone, or a
                        digital tablet.
                    </li>
                    <li>
                        <strong>Content:</strong> Refers to content such as text, images, videos, or other information
                        that can be
                        posted, uploaded, linked to, or otherwise made available by You, regardless of the form of that
                        Content.
                    </li>
                    <li>
                        <strong>Feedback:</strong> Means feedback, innovations, or suggestions sent by You regarding the
                        attributes,
                        performance, or features of our Services.
                    </li>
                    <li>
                        <strong>Services:</strong> Refers collectively to the Site, App, and all related services we
                        provide.
                    </li>
                    <li>
                        <strong>Third-party Social Media Service:</strong> Means any services or content (including
                        data, information,
                        products, or services) provided by a third-party that may be displayed, included, or made
                        available by the
                        Services.
                    </li>
                </ul>


                <h2>3. Applicability of Terms</h2>
                <p>
                    These Terms govern your use of the Services from the moment you create an account or access the
                    Services,
                    and they will continue to apply unless replaced by updated Terms. These Terms also govern any
                    content you
                    upload to the Services and any data collected about you through your use of the Services.
                </p>
                <p>
                    If we update these Terms in the future, you will be notified and required to agree to the updated
                    Terms
                    to continue using the Services.
                </p>


                <h2>4. Eligibility</h2>
                <p>You must meet the following age requirements to use the Services:</p>
                <ul>
                    <li>
                        <strong>Minimum Age:</strong> You must be at least 16 years old, or the minimum age required in
                        your country to
                        consent to use the Services.
                    </li>
                    <li>
                        <strong>Parental Consent for Minors:</strong> If you are a minor, you must have your parent or
                        legal guardian’s
                        permission to use the Services and ask them to read these Terms with you.
                    </li>
                </ul>
                <h3>Prohibited Use by Underage Users</h3>
                <p>
                    If you are below the minimum age in your country or do not have parental consent as required, you
                    are strictly
                    prohibited from using the Services.
                </p>
                <h3>Account Termination for Underage Users</h3>
                <p>
                    We reserve the right to terminate or suspend any account if we discover or have reason to believe
                    that the account
                    is being used by someone below the required age.
                </p>

                <h2>5. User Accounts</h2>
                <p>
                    When You create an account with Us, You must provide Us information that is accurate, complete, and
                    current at
                    all times. Failure to do so constitutes a breach of the Terms, which may result in immediate
                    termination of your
                    account on our Services.
                </p>
                <p>
                    You are responsible for safeguarding the password that You use to access the Services and for any
                    activities or
                    actions under Your password, whether Your password is with our Services or a Third-Party Social
                    Media Service.
                </p>
                <p>
                    You agree not to disclose Your password to any third party. You must notify Us immediately upon
                    becoming aware of
                    any breach of security or unauthorized use of Your account.
                </p>
                <p>
                    You may not use as a username the name of another person or entity or that is not lawfully available
                    for use, a
                    name or trademark that is subject to any rights of another person or entity other than You without
                    appropriate
                    authorization, or a name that is otherwise offensive, vulgar, or obscene.
                </p>

                <h2>6. Content</h2>
                <h3>Ownership of Content</h3>
                <p>
                    Our Services allows You to post Content. You are responsible for the Content that You post to the
                    Services,
                    including its legality, reliability, and appropriateness.
                </p>
                <p>You retain ownership of any content you upload or share on the Services.</p>
                <h3>License to Us</h3>
                <p>
                    By posting or sharing content, you grant us a worldwide, perpetual, irrevocable, royalty-free,
                    transferable, and
                    sub-licensable license to:
                </p>
                <ul>
                    <li>Use, reproduce, modify, adapt, publish, distribute, publicly perform, publicly display, and
                        create derivative works of your Content.
                    </li>
                    <li>
                        Use Your Content for any purpose, including but not limited to marketing, advertising,
                        promotional activities,
                        platform development, partnerships, and redistribution on any platform or medium.
                    </li>
                    <li>
                        Display Your Content in connection with other content or promotional materials across our
                        accounts, platforms,
                        or affiliated services.
                    </li>
                </ul>
                <h3>Scope of License</h3>
                <p>This license applies to:</p>
                <ul>
                    <li>All existing Content and data associated with your account, regardless of when it was uploaded
                        or posted.
                    </li>
                    <li>Any future content or data you upload, share, or otherwise make available through the
                        Services.
                    </li>
                </ul>
                <h3>Retention After Account Termination</h3>
                <p>
                    We retain the right to use your content indefinitely, even after you delete your account or
                    terminate your
                    relationship with Us, for legal, operational, marketing, or promotional purposes.
                </p>
                <h3>Content Removal</h3>
                <p>
                    We may continue to use your content even if you delete it or terminate your account. We also reserve
                    the right to
                    remove or modify any content that violates these Terms or applicable laws.
                </p>
                <h3>User Responsibility for Content</h3>
                <p>
                    You represent and warrant that You own all rights to the Content you upload and grant us the rights
                    and license
                    as provided in these Terms. Your content must not infringe on any third-party rights, violate
                    applicable laws,
                    or include illegal, offensive, or abusive material.
                </p>


                <h2>7. Analytics</h2>
                <p>
                    We collect analytics data to monitor and improve the Services. This includes, but is not limited to:
                </p>
                <ul>
                    <li>Behavioral data, such as mouse movements, clicks, scrolling activity, and heatmaps.</li>
                    <li>Session recordings, which capture user interactions with the Services.</li>
                    <li>Device information, IP addresses, and general usage patterns.</li>
                </ul>
                <p>
                    We may use third-party tools to gather this data. These tools may store anonymized or aggregated
                    data on their
                    servers, subject to their respective privacy policies.
                </p>
                <p>
                    Analytics data is used solely for improving the functionality, design, and overall user experience
                    of the Services.
                    Where required by applicable law, we will request explicit consent before collecting certain types
                    of analytics
                    data.
                </p>
                <p>
                    For more information, please review our{' '}
                    <a href="https://solt.app/privacy" target="_blank" rel="noopener noreferrer">
                        Privacy Policy
                    </a>
                    .
                </p>

                <h2>8. Prohibited Activities / Content Restrictions</h2>
                <h3>Zero Tolerance Policy</h3>
                <p>
                    Solt has a zero-tolerance policy for objectionable content, abusive behavior, or any activity that
                    violates these
                    Terms. Users found engaging in such behavior will face immediate content removal, account
                    suspension, or termination
                    without prior notice.
                </p>
                <p>You agree not to:</p>
                <ul>
                    <li>Upload illegal, abusive, or offensive content.</li>
                    <li>Use the Services for spam, fraud, or unlawful activities.</li>
                    <li>Attempt to hack, disrupt, or reverse-engineer the Services.</li>
                    <li>Violate any applicable laws while using the Services.</li>
                </ul>
                <p>
                    We are not responsible for the Content of the Services' users. You expressly understand and agree
                    that You are solely
                    responsible for the Content and for all activity that occurs under your account, whether done so by
                    You or any third
                    person using Your account.
                </p>
                <p>
                    You may not transmit any Content that is unlawful, offensive, upsetting, intended to disgust,
                    threatening, libelous,
                    defamatory, obscene, or otherwise objectionable. Examples of such objectionable Content include, but
                    are not limited to,
                    the following:
                </p>
                <ul>
                    <li>Unlawful or promoting unlawful activity.</li>
                    <li>
                        Defamatory, discriminatory, or mean-spirited content, including references or commentary about
                        religion, race,
                        sexual orientation, gender, national/ethnic origin, or other targeted groups.
                    </li>
                    <li>
                        Spam, machine- or randomly-generated, constituting unauthorized or unsolicited advertising,
                        chain letters,
                        any other form of unauthorized solicitation, or any form of lottery or gambling.
                    </li>
                    <li>
                        Containing or installing any viruses, worms, malware, trojan horses, or other content that is
                        designed or intended
                        to disrupt, damage, or limit the functioning of any software, hardware, or telecommunications
                        equipment or to
                        damage or obtain unauthorized access to any data or other information of a third person.
                    </li>
                    <li>
                        Infringing on any proprietary rights of any party, including patent, trademark, trade secret,
                        copyright, right of
                        publicity, or other rights.
                    </li>
                    <li>Impersonating any person or entity including Us and our employees or representatives.</li>
                    <li>Violating the privacy of any third person.</li>
                    <li>False information and features.</li>
                </ul>
                <p>
                    We reserve the right, but not the obligation, to, in our sole discretion, determine whether or not
                    any Content is
                    appropriate and complies with these Terms, and to refuse or remove this Content. We further reserve
                    the right to make
                    formatting and edits and change the manner of any Content.
                </p>
                <p>
                    We cannot control all content posted by users and/or third parties on the Services. You agree to use
                    the Services at
                    your own risk. You understand that by using the Services, You may be exposed to content that You may
                    find offensive,
                    indecent, incorrect, or objectionable, and You agree that under no circumstances will We be liable
                    in any way for any
                    content, including any errors or omissions in any content, or any loss or damage of any kind
                    incurred as a result of
                    your use of any content.
                </p>

                <h2>9. Content Backups</h2>
                <p>
                    Although regular backups of Content are performed, We do not guarantee there will be no loss or
                    corruption of data.
                    Corrupt or invalid backup points may be caused by, without limitation, Content that is corrupted
                    prior to being backed
                    up or that changes during the time a backup is performed.
                </p>
                <p>
                    We will provide support and attempt to troubleshoot any known or discovered issues that may affect
                    the backups of Content.
                    But You acknowledge that We have no liability related to the integrity of Content or the failure to
                    successfully restore
                    Content to a usable state.
                </p>
                <p>
                    You agree to maintain a complete and accurate copy of any Content in a location independent of the
                    Services.
                </p>


                <h2>10. Intellectual Property</h2>
                <h3>Our Content</h3>
                <p>
                    We own all rights, title, and interest in the Services’ design, code, and proprietary content
                    (excluding
                    user-generated content).
                </p>
                <h3>Restrictions</h3>
                <p>
                    You may not copy, distribute, modify, or reverse-engineer any part of the Services.
                </p>
                <p>
                    The Services and its original content (excluding Content provided by You or other Users), features,
                    and
                    functionality are and will remain the exclusive property of Us and its licensors.
                </p>
                <p>
                    The Services is or will be protected by copyright, trademark, and other laws of both the Country and
                    foreign
                    countries.
                </p>
                <p>
                    Our trademarks and trade dress may not be used in connection with any product or service without the
                    prior
                    written consent of Solt.
                </p>

                <h2>11. Termination</h2>
                <h3>Termination by Us</h3>
                <p>
                    We may suspend or terminate your access to and use of the Services, including suspending access to
                    or terminating
                    your Account, at any time, for any reason, and at our sole discretion, without prior notice or
                    liability.
                </p>
                <h3>Termination by You</h3>
                <p>
                    You may terminate your account at any time by contacting us at{' '}
                    <a href="mailto:hello@solt.app">hello@solt.app</a>.
                </p>
                <h3>Effect of Termination</h3>
                <p>
                    Upon termination, all licenses and rights granted to you under these Terms will immediately cease.
                    We retain the
                    right to continue using any content you have uploaded.
                </p>


                <h2>12. Warranty Disclaimers</h2>
                <h3>"As Is" Services</h3>
                <p>
                    The Services are provided "as is," without warranties of any kind. We explicitly disclaim all
                    implied warranties,
                    including but not limited to merchantability, fitness for a particular purpose, quiet enjoyment, and
                    non-infringement.
                </p>
                <h3>No Guarantee of Availability</h3>
                <p>
                    We do not guarantee that the Services will meet your requirements or that they will be available on
                    an uninterrupted,
                    secure, or error-free basis.
                </p>
                <h3>Third-Party Content</h3>
                <p>
                    The Services may include materials provided by third parties. We are not responsible for the
                    accuracy, reliability,
                    or completeness of such materials.
                </p>
                <h3>"AS IS" and "AS AVAILABLE" Disclaimer</h3>
                <p>
                    The Services is provided to You "AS IS" and "AS AVAILABLE" and with all faults and defects without
                    warranty of any
                    kind. To the maximum extent permitted under applicable law, Solt, on its own behalf and on behalf of
                    its and their
                    respective licensors and service providers, expressly disclaims all warranties, whether express,
                    implied, statutory
                    or otherwise, with respect to the Services, including all implied warranties of merchantability,
                    fitness for a
                    particular purpose, title and non-infringement, and warranties that may arise out of course of
                    dealing, course of
                    performance, usage or trade practice.
                </p>
                <p>
                    Without limitation to the foregoing, We provides no warranty or undertaking, and makes no
                    representation of any kind
                    that the Services will meet Your requirements, achieve any intended results, be compatible or work
                    with any other
                    software, applications, systems or services, operate without interruption, meet any performance or
                    reliability
                    standards or be error free or that any errors or defects can or will be corrected.
                </p>
                <p>
                    Without limiting the foregoing, neither Us nor any of the company's providers makes any
                    representation or warranty
                    of any kind, express or implied:
                </p>
                <ul>
                    <li>As to the operation or availability of the Services, or the information, content, and materials
                        or products included thereon.
                    </li>
                    <li>That the Services will be uninterrupted or error-free.</li>
                    <li>As to the accuracy, reliability, or currency of any information or content provided through the
                        Services.
                    </li>
                    <li>
                        That the Services, its servers, the content, or e-mails sent from or on Our behalf are free of
                        viruses, scripts,
                        trojan horses, worms, malware, timebombs, or other harmful components.
                    </li>
                </ul>
                <p>
                    Some jurisdictions do not allow the exclusion of certain types of warranties or limitations on
                    applicable statutory
                    rights of a consumer, so some or all of the above exclusions and limitations may not apply to You.
                    But in such a case,
                    the exclusions and limitations set forth in this section shall be applied to the greatest extent
                    enforceable under
                    applicable law.
                </p>


                <h2>13. Limitation of Liability</h2>
                <p>To the fullest extent permitted by law, we will not be liable for:</p>
                <ul>
                    <li>Lost profits, loss of data, or content.</li>
                    <li>Service interruptions, system failures, or the cost of substitute services.</li>
                </ul>
                <p>
                    Notwithstanding any damages that You might incur, the entire liability of Solt and any of its
                    suppliers under any
                    provision of these Terms and Your exclusive remedy for all of the foregoing shall be limited to the
                    amount actually
                    paid by You through the Services or 50 EUR if You haven't purchased anything through the Service.
                </p>
                <p>
                    To the maximum extent permitted by applicable law, in no event shall Solt or its suppliers be liable
                    for any special,
                    incidental, indirect, or consequential damages whatsoever (including, but not limited to, damages
                    for loss of profits,
                    loss of data or other information, for business interruption, for personal injury, or loss of
                    privacy arising out of
                    or in any way related to the use of or inability to use the Services, third-party software and/or
                    third-party hardware
                    used with the Service, or otherwise in connection with any provision of these Terms), even if We or
                    any supplier has
                    been advised of the possibility of such damages and even if the remedy fails of its essential
                    purpose.
                </p>
                <p>
                    Some states do not allow the exclusion of implied warranties or limitation of liability for
                    incidental or consequential
                    damages, which means that some of the above limitations may not apply. In these states, each party's
                    liability will be
                    limited to the greatest extent permitted by law.
                </p>


                <h2>14. Community Guidelines</h2>
                <p>To maintain a respectful and engaging environment, you agree to:</p>
                <ul>
                    <li>Avoid posting content that is abusive, offensive, defamatory, or discriminatory.</li>
                    <li>Interact with other users in a respectful manner.</li>
                    <li>Report any violations of these guidelines to us.</li>
                </ul>
                <p>
                    Failure to comply with these guidelines may result in content removal, account suspension, or
                    termination at our
                    sole discretion.
                </p>

                <h2>15. Payment Terms (if applicable)</h2>
                <p>If the Services include premium features, subscriptions, or in-app purchases:</p>
                <ul>
                    <li>You agree to pay all applicable fees, which are non-refundable except as required by law.</li>
                    <li>We reserve the right to change pricing or payment terms at any time.</li>
                    <li>Any taxes or other fees associated with the purchase are your responsibility.</li>
                </ul>


                <h2>16. Feedback and Suggestions</h2>
                <p>
                    By providing feedback, suggestions, or ideas about the Services (“Feedback”), you grant us a
                    perpetual, irrevocable,
                    royalty-free, transferable, and sublicensable license to use, modify, and commercialize the Feedback
                    for any purpose,
                    without compensation.
                </p>


                <h2>17. Third-Party Services</h2>
                <p>
                    The Services may include links to or integrations with third-party services that are not owned or
                    controlled by Us.
                </p>
                <p>
                    We do not endorse or control these third-party services and are not responsible for their content or
                    practices. Use
                    of third-party services is at your own risk.
                </p>
                <p>
                    We have no control over, and assume no responsibility for, the content, privacy policies, or
                    practices of any
                    third-party websites or services. You further acknowledge and agree that Solt shall not be
                    responsible or liable,
                    directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection
                    with the use of or
                    reliance on any such content, goods, or services available on or through any such websites or
                    services.
                </p>
                <p>
                    We strongly advise You to read the terms and conditions and privacy policies of any third-party
                    websites or services
                    that You visit.
                </p>


                <h2>18. Service Availability</h2>
                <p>
                    We do not guarantee uninterrupted availability of the Services and may modify, suspend, or terminate
                    the Services at
                    any time without liability.
                </p>

                <h2>19. Force Majeure</h2>
                <p>
                    We are not liable for delays or failures caused by circumstances beyond our reasonable control,
                    including but not
                    limited to acts of God, natural disasters, government actions, cyberattacks, or technical failures.
                </p>

                <h2>20. Governing Law</h2>
                <p>
                    These Terms are governed by and construed in accordance with the laws of Romania, without regard to
                    its conflict of
                    law principles. Your use of the Services may also be subject to other local, state, national, or
                    international laws.
                </p>


                <h2>21. Dispute Resolution</h2>
                <p>
                    If You have any concern or dispute about the Services, You agree to first try to resolve the dispute
                    informally by
                    contacting Us.
                </p>

                <p>
                    <b>Exclusive Jurisdiction:</b> Any other disputes will be resolved in the courts located in Romania.
                </p>
                <p>
                    We reserve the right to determine the method and forum for resolving any disputes, including
                    arbitration or litigation.
                </p>


                <h2>22. Indemnification</h2>
                <p>
                    You agree to indemnify and hold us, our affiliates, and our respective officers, employees, and
                    agents harmless
                    from any claims, damages, or expenses arising out of:
                </p>
                <ul>
                    <li>Your content.</li>
                    <li>Your violation of these Terms.</li>
                    <li>Your use of the Services in violation of any applicable laws or third-party rights.</li>
                </ul>


                <h2>23. Mobile App-Specific Terms</h2>
                <p>
                    By downloading or using our App, you agree to the terms and conditions set forth by the relevant app
                    store
                    (e.g., Apple App Store or Google Play).
                </p>

                <h2>24. Feedback on Translation or Language</h2>
                <p>
                    If these Terms are provided in multiple languages, the English version will prevail in the event of
                    any conflict
                    or discrepancy.
                </p>

                <h2>25. Promotional Communications</h2>
                <p>
                    By using the Services, you agree to receive promotional emails or notifications from us. You may opt
                    out at any time
                    by following the unsubscribe link or contacting us at{' '}
                    <a href="mailto:hello@solt.app">hello@solt.app</a>.
                </p>

                <h2>26. Partnerships, Acquisitions, and Data Usage</h2>
                <h3>Transfer of Content and Data in Business Changes</h3>
                <p>
                    If we enter into a partnership, are acquired by another entity, merge, or undergo any form of
                    corporate transformation,
                    all user content, data, and accounts may be transferred to the new entity or partner. By agreeing to
                    these Terms,
                    you consent to such a transfer and acknowledge that the new entity will inherit all rights and
                    obligations under
                    these Terms.
                </p>
                <h3>Business Partnerships and Data Use</h3>
                <p>
                    We reserve the right to establish B2B partnerships or collaborations with third parties and may
                    share or utilize
                    user data, content, and analytics in any manner that supports the partnership, platform growth, or
                    operational
                    objectives. Such usage may include, but is not limited to, marketing, product development,
                    advertising, or other
                    commercial purposes, as long as it complies with applicable laws and our Privacy Policy.
                </p>
                <h3>Notice of Significant Changes</h3>
                <p>
                    In the event of significant business changes, we will notify you through the Services, email, or
                    other reasonable
                    communication methods.
                </p>


                <h2>27. General Terms</h2>

                <h3>27.1 Reservation of Rights</h3>
                <p>
                    We and our licensors exclusively own all rights, title, and interest in and to the Services,
                    including all associated
                    intellectual property rights. You acknowledge that the Services are protected by copyright and other
                    applicable
                    intellectual property laws.
                </p>
                <p>
                    We reserve the right to apply for and enforce trademark, service mark, or other proprietary rights
                    protections in the
                    future. You agree not to remove, alter, or obscure any copyright, potential trademark, or other
                    proprietary rights
                    notices incorporated in or accompanying the Services.
                </p>

                <h3>27.2 Entire Agreement</h3>
                <p>
                    These Terms constitute the entire and exclusive understanding and agreement between us and you
                    regarding the Services
                    and supersede all prior oral or written communications, understandings, or agreements between us and
                    you regarding
                    the Services.
                </p>
                <p>
                    Except where provided by applicable law, you may not assign or transfer these Terms, by operation of
                    law or otherwise,
                    without our prior written consent. Any attempt to assign or transfer these Terms without such
                    consent will be null and
                    void. We may freely assign or transfer these Terms without restriction. Subject to the foregoing,
                    these Terms will
                    bind and benefit the parties, their successors, and permitted assigns.
                </p>

                <h3>27.3 Notices</h3>
                <p>Any notices or other communications provided by us under these Terms will be made by:</p>
                <ul>
                    <li>(i) sending an email to the address associated with your account; or</li>
                    <li>(ii) posting the notice on the Services; or</li>
                    <li>(iii) any other reasonable means.</li>
                </ul>
                <p>The date of receipt will be deemed the date on which such notice is transmitted or posted.</p>

                <h3>27.4 Waiver of Rights</h3>
                <p>
                    Our failure to enforce any right or provision of these Terms will not be considered a waiver of such
                    right or
                    provision. A waiver of any right or provision will only be effective if it is in writing and signed
                    by an authorized
                    representative of our team.
                </p>
                <p>
                    Except as expressly provided in these Terms, the exercise of any remedy under these Terms by either
                    party will be
                    without prejudice to any other remedies available under these Terms or otherwise.
                </p>

                <h2>27.5 California Consumer Rights</h2>
                <p>
                    If you are a resident of California, you have specific rights under the California Consumer Privacy
                    Act (CCPA). These include:
                </p>
                <ul>
                    <li>The right to know what personal information we collect, use, and disclose about you.</li>
                    <li>The right to request the deletion of your personal information.</li>
                    <li>The right to opt out of the sale of your personal information.</li>
                </ul>
                <p>
                    Solt does not sell personal information as defined under the CCPA. However, if you have questions
                    about our practices or wish to exercise your rights, you may contact us at
                    <a href="mailto:hello@solt.app">hello@solt.app</a>.
                </p>
                <p>
                    For more details about your privacy rights, please refer to our <a
                    href="https://www.solt.app/privacy">Privacy Policy</a>.
                </p>


                <h2>28. End User License Agreement (EULA)</h2>
                <p>
                    By downloading or using the Solt mobile application from the Apple App Store, you acknowledge that
                    your use of the
                    application is subject to the Apple Media Services Terms and Conditions, including the Standard End
                    User License
                    Agreement (EULA) provided by Apple Inc. The full text of Apple’s EULA is available{' '}
                    <a
                        href="https://www.apple.com/legal/internet-services/itunes/dev/stdeula/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        here
                    </a>.
                </p>

                <h2>29. Contact Information</h2>
                <p>If you have any questions about these Terms or the Services, you may contact us at:</p>
                <ul>
                    <li>
                        By sending us an email: <a href="mailto:hello@solt.app">hello@solt.app</a>
                    </li>
                    <li>
                        By visiting our website: <a href="https://www.solt.app" target="_blank"
                                                    rel="noopener noreferrer">www.solt.app</a>
                    </li>
                </ul>

            </div>
        </>
    );
};

export default Terms;
