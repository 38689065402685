import React from 'react';

const CookiePolicy: React.FC = () => {
    return (
        <div style={{padding: '2rem'}}>
            <h1>Cookie Policy</h1>
            <p>Our Cookie Policy was last updated on <strong>16 December 2024</strong>.</p>
            
            <h2>What Are Cookies?</h2>
            <p>
                Cookies are small text files stored on your device when you visit a website. They help us improve your
                experience, remember preferences, and analyze how you interact with our Services.
            </p>

            <h2>How We Use Cookies</h2>
            <p>We use cookies and similar technologies for the following purposes:</p>
            <ul>
                <li>
                    <strong>Essential Cookies:</strong> Ensure the proper functioning of our Services.
                </li>
                <li>
                    <strong>Performance Cookies:</strong> Analyze how users interact with our website and app to
                    improve usability.
                </li>
                <li>
                    <strong>Personalization Cookies:</strong> Remember your preferences and tailor content to your
                    interests.
                </li>
                <li>
                    <strong>Advertising Cookies:</strong> Deliver relevant advertisements and track ad performance.
                </li>
            </ul>

            <h2>Managing Cookies</h2>
            <p>
                You can manage or disable cookies through your browser settings. Please note that disabling cookies may
                impact your experience with our Services.
            </p>

            <h2>Third-Party Cookies</h2>
            <p>
                We may use cookies from third-party providers, such as analytics or advertising platforms, to better
                understand usage and deliver personalized ads.
            </p>

            <h2>Updates to This Policy</h2>
            <p>
                We may update this Cookie Policy from time to time. Please review this section periodically to stay
                informed
                about how we use cookies.
            </p>

            <p>
                For more details, contact us at <a href="mailto:hello@solt.app">hello@solt.app</a>.
            </p>
        </div>
    );
};

export default CookiePolicy;
