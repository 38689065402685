import React from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import blogData from "../assets/blogPostData.json";
import "./BlogPost.css"; // Scoped CSS with .blog-post-page
import soltLogo from "../assets/logo.png";

interface Post {
  title: string;
  image: string;
  location: string;
  googlePlaceId: string;
  location_country: string;
  location_city: string;
  location_restaurant_name: string;
  location_street_name:  string;
}

interface Analysis {
  Page: string;
  Header: {
    DishName: string;
    FeaturedImage: string;
    RatingAndLikes: string;
  };
  ContentSections: {
    Overview: {
      Cuisine: string;
      AvailableAt: string;
      IdealFor: string;
    };
    DishHighlights: string[];
    WhereToTryIt: {
      ServedAt: string;
      Atmosphere: string;
    };
    RelatedDishes: string[];
    CustomerReviews: {
      UserReviews: string[];
    };
  };
}

interface BlogData {
  id: number;
  post: Post;
  analysis: Analysis;
  UrlTag: string;
}

const BlogPost: React.FC = () => {
  const { blogpostname } = useParams<{ blogpostname: string }>();

  const postData = blogData.find(
    (item: BlogData) => item.UrlTag === blogpostname
  );

  if (!postData) {
    return (
      <div className="blog-post-page container mx-auto p-4">Post not found</div>
    );
  }

  const { post, analysis } = postData;

  const pageTitle = `What to eat in ${post.location_city} , ${post.location_country}: ${analysis.Header.DishName}  - ${analysis.Header.DishName} at ${post.location_restaurant_name} in 2025`;
  const pageDescription = analysis.Header.RatingAndLikes;
  const canonicalUrl = `https://solt.app/blog/${blogpostname}`;
  return (
    <div className="blog-post-page">
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>

      <div className="top-navigation">
        <div className="nav-content">
          <a href="/">
            <img src={soltLogo} alt="Solt logo" className="solt-logo" />
          </a>
          <div className="header-buttons">
            <a
              href="https://apps.apple.com/app/solt-the-app-for-foodies/id6450785241"
              target="_blank"
              rel="noopener noreferrer"
              className="ios-app-button-link"
              aria-label="Download the app"
            >
              <button className="join-creator-button">
                <img
                  src={require("../assets/apple-icon.png")}
                  alt="Apple Icon"
                  className="apple-icon"
                />
                Download the app
              </button>
            </a>
          </div>
        </div>
      </div>

      {/* Blog Post Content */}
      <div className="blog-post">
        <div className="featured-image">
          <img src={post.image} alt={post.title} />
        </div>

        <div className="post-content">
          <div className="post-header">
            <h2 className="post-title">{post.title}</h2>
            <div className="cuisine-tag">
              {analysis.ContentSections.Overview.Cuisine}
            </div>
            <p className="post-location">{post.location}</p>
            <p className="post-rating">{analysis.Header.RatingAndLikes}</p>
          </div>

          <p className="atmosphere">
            {analysis.ContentSections.WhereToTryIt.Atmosphere}
          </p>

          <div className="left-column">
            <div className="highlights-section">
              <h3 className="section-title">Highlights</h3>
              <ul className="highlights-list">
                {analysis.ContentSections.DishHighlights.map(
                  (highlight, index) => (
                    <li key={index}>{highlight}</li>
                  )
                )}
              </ul>
            </div>

            <div className="reviews-section">
              <h3 className="section-title">Reviews</h3>
              {analysis.ContentSections.CustomerReviews.UserReviews.map(
                (review, index) => (
                  <div key={index} className="review-item">
                    <p className="review-text">{review}</p>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogPost;
